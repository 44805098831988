import dayjs from "dayjs";
import { Flex } from "antd";

import { openFile } from "../../../../../../../utils/request/manager";

import { ReactComponent as File } from "../../../../../../../icon/file.svg";

const FileCard = ({ fileData }) => (
  <Flex
    gap={20}
    style={{ width: 240, cursor: "pointer" }}
    onClick={() => openFile(fileData?.id)}
  >
    <File />
    <Flex gap={6} vertical>
      <span style={{ fontWeight: 500 }}>{fileData?.name}</span>
      <span>{dayjs(fileData?.createDateTime?.$date).format("MM/DD/YYYY")}</span>
    </Flex>
  </Flex>
);

export default FileCard;
