import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../../context";
import {
  Breadcrumb,
  Button,
  Divider,
  Flex,
  Form,
  InputNumber,
  Progress,
  Radio,
  Select,
  theme,
} from "antd";
import Bullet from "../../../../atom/Bullet";

import StylesContainer from "./styles";

import { PROPOSAL_DEFAULT_DATA_LEVEL } from "../../constants";
import { LIST_STEP_DATA } from "./constants";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../utils/helper/general";
import { ReactComponent as IconGrowth } from "./images/icon_growth.svg";
import { ReactComponent as IconIncome } from "./images/icon_income.svg";
import { ReactComponent as IconGrowthIncome } from "./images/icon_growth_income.svg";
import { RightOutlined } from "@ant-design/icons";

const QuestionnaireContentLevel = ({
  handleClose,
  handleCreateProposal,
  open,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  const investmentObjective = Form.useWatch("investmentObjective", form);

  const activeUserData = state.managedUsers.find(
    it => it.email === state.activeEmail
  );

  const { title, description } = LIST_STEP_DATA[currentStepIndex];

  useEffect(() => {
    if (open) {
      setCurrentStepIndex(0);
      form.setFieldsValue(PROPOSAL_DEFAULT_DATA_LEVEL);
    }
  }, [open]);

  const handleSubmit = () => {
    handleCreateProposal({
      ...PROPOSAL_DEFAULT_DATA_LEVEL,
      ...form.getFieldsValue(),
    });
    setCurrentStepIndex(0);
  };

  return (
    <StylesContainer align="center" token={token} vertical>
      <header>
        <Flex align="center" className="copy-container" justify="space-between">
          <h4>
            Proposal:{" "}
            <b>
              {activeUserData?.firstName} {activeUserData?.lastName}
            </b>
          </h4>
          <Button onClick={handleClose} shape="round">
            Close
          </Button>
        </Flex>
        <Flex align="center" justify="end" className="breadcrumb-container">
          <Breadcrumb
            items={[
              {
                className: "active",
                title: <p>Start Planning</p>,
              },
              {
                className: currentStepIndex > 0 && "active",

                title: <p>Select Product</p>,
              },
              {
                className: currentStepIndex > 1 && "active",
                title: <p>Review Proposal</p>,
              },
              {
                title: (
                  <Button
                    disabled={currentStepIndex !== 2}
                    onClick={handleSubmit}
                    shape="round"
                  >
                    Save Proposal
                  </Button>
                ),
              },
            ]}
            separator={<RightOutlined />}
          />
        </Flex>
        <Progress
          percent={(100 / LIST_STEP_DATA.length) * (currentStepIndex + 1)}
          showInfo={false}
          strokeColor={{ from: "#E2E86C", to: "#4F8083" }}
          strokeLinecap="square"
          trailColor="#A9C8CA"
        />
      </header>

      <main>
        <div className="description-container">
          <h2 className="title">{title}</h2>
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <div className="form-container">
          <Form id="levelQuestionnaireForm" form={form} layout="vertical">
            <Form.Item
              hidden={currentStepIndex !== 0}
              name="investmentObjective"
            >
              <Radio.Group className="aim-container">
                <Flex align="center" gap={24} vertical>
                  <Radio.Button value="growth">
                    <Flex align="center" gap={24}>
                      <IconGrowth /> Drive Growth
                    </Flex>
                  </Radio.Button>
                  <Radio.Button value="income">
                    <Flex align="center" gap={24}>
                      <IconIncome /> Drive Income
                    </Flex>
                  </Radio.Button>
                  <Radio.Button value="growth_income">
                    <Flex align="center" gap={24}>
                      <IconGrowthIncome />
                      Growth & Income
                    </Flex>
                  </Radio.Button>
                </Flex>
              </Radio.Group>
            </Form.Item>
            <Form.Item hidden={currentStepIndex !== 1} name="riskTolerance">
              <Radio.Group className="risk-container">
                <Flex gap={20} vertical>
                  <Radio value="Preservation">Preservation</Radio>
                  <Radio value="Conservative">Conservative</Radio>
                  <Radio value="Moderate">Moderate</Radio>
                  <Radio value="Growth">Growth</Radio>
                  <Radio value="Aggressive">Aggressive</Radio>
                  <Flex gap={16} vertical>
                    <Divider />
                    <Radio value="unknown">I don't know</Radio>
                  </Flex>
                </Flex>
              </Radio.Group>
            </Form.Item>

            <div
              className={
                currentStepIndex === 2 ? "investment-values-container" : ""
              }
            >
              <Form.Item
                hidden={currentStepIndex !== 2}
                label={token.proposal_copy_investment_amount}
                name="investmentAmount"
              >
                <InputNumber
                  formatter={getCurrencyValue}
                  max={100000000}
                  min={0}
                  onClick={selectValue}
                  parser={cleanCurrencyValue}
                  size="large"
                  step={1000}
                />
              </Form.Item>
              <Form.Item
                hidden={
                  currentStepIndex !== 2 || investmentObjective === "growth"
                }
                label="Year to Start Withdrawals"
                name="yearToStartWithdrawals"
              >
                <InputNumber
                  max={80}
                  min={1}
                  onClick={selectValue}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                hidden={currentStepIndex !== 2}
                label="Length of Investment"
                name="investmentDuration"
              >
                <InputNumber
                  addonAfter={
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Select
                        options={[
                          {
                            label: "Years",
                            value: "years",
                          },
                          {
                            disabled: true,
                            label: "Quarters",
                            value: "quarters",
                          },
                          {
                            disabled: true,
                            label: "Months",
                            value: "months",
                          },
                        ]}
                        style={{ width: 120 }}
                        value="years"
                      />
                    </Form.Item>
                  }
                  max={80}
                  min={1}
                  onClick={selectValue}
                  size="large"
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </main>

      <footer>
        {currentStepIndex === 0 ? (
          <Button
            block={isSmallScreen}
            onClick={handleClose}
            shape="round"
            style={{ order: isSmallScreen ? 3 : 1 }}
            type="text"
          >
            Cancel
          </Button>
        ) : (
          <Button
            block={isSmallScreen}
            onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
            shape="round"
            style={{ order: isSmallScreen ? 3 : 1 }}
            type="text"
          >
            Previous
          </Button>
        )}

        <Flex gap={12} justify="center" style={{ order: 2 }}>
          {Array(LIST_STEP_DATA.length)
            .fill(null)
            .map((_, index) => (
              <Bullet
                color={currentStepIndex === index ? "#4F8083" : "#749B9E50"}
                key={index}
                size={12}
              />
            ))}
        </Flex>

        {currentStepIndex === 2 ? (
          <Button
            block={isSmallScreen}
            disabled={state.loading}
            onClick={handleSubmit}
            shape="round"
            style={{ order: isSmallScreen ? 1 : 3 }}
            type="primary"
          >
            Let’s Go
          </Button>
        ) : (
          <Button
            block={isSmallScreen}
            onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
            shape="round"
            style={{ order: isSmallScreen ? 1 : 3, width: 126 }}
            type="primary"
          >
            Continue
          </Button>
        )}
      </footer>
    </StylesContainer>
  );
};

export default QuestionnaireContentLevel;
