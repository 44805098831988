import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    border-bottom: 1px solid #c9c8c8;
    padding: 20px 32px;

    h4 {
      color: #215054;
      font-size: 18px;
      font-style: normal;
      line-height: 15px;
      margin: 0;
    }
  }

  main {
    display: flex;
    gap: 36px;
    padding: ${({ token }) => token.manager_page_body_padding};
    width: 100%;

    .filters-container {
      background: #f1f5f6;
      border: 1px solid #d7d7d7;
      border-radius: 12px;
      height: fit-content;
      padding: 24px;
      min-width: 261px;
      width: 261px;
    }

    .ant-table-wrapper .ant-table-thead > tr > th {
      background: #fff;
      font-weight: 700;

      &::before {
        display: none;
      }
    }
  }
`;

export default StylesContainer;
