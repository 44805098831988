import { Button, Row } from "antd";
import CardProduct from "../../../../molecule/CardProduct";

import StyledModal from "./styles";

import Close from "../../../../icon/Close";

const ModalRelatedProducts = ({
  cardActions,
  compareProducts,
  favoriteFinancialProducts,
  handleCardClick,
  handleClose,
  hideFavoriteProducts,
  open,
  productsList,
  selectedProduct,
}) => {
  const onCardClick = cardId => {
    handleCardClick(cardId);
  };

  return (
    <StyledModal
      open={open}
      onCancel={handleClose}
      width={980}
      title="Related Products"
      closeIcon={<Close />}
      footer={
        <Row justify="start">
          <Button
            type="primary"
            shape="round"
            style={{ width: 107 }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Row>
      }
      centered
    >
      {favoriteFinancialProducts?.length && (
        <div className="list-title">Similar Products</div>
      )}
      <Row
        style={{ gap: 32, maxHeight: "70vh", overflow: "auto" }}
        wrap={!favoriteFinancialProducts?.length}
      >
        {productsList
          ?.filter(product => !compareProducts.includes(product._id))
          ?.map(product => (
            <CardProduct
              actions={cardActions}
              handleCardClick={onCardClick}
              isActionButtonActive={selectedProduct === product._id}
              key={product._id}
              labels={[
                product.broadCategory,
                product.globalCategory,
                product.productCategory,
              ]}
              product={product}
              width="267px"
            />
          ))}
      </Row>
      {favoriteFinancialProducts?.length && !hideFavoriteProducts && (
        <div style={{ marginTop: 18 }}>
          <div className="list-title">Advisor Preferred</div>
          <Row style={{ gap: 32, overflow: "auto" }} wrap={false}>
            {productsList
              .filter(it => favoriteFinancialProducts.includes(it._id))
              .filter(product => !compareProducts.includes(product._id))
              .map(product => (
                <CardProduct
                  actions={cardActions}
                  handleCardClick={onCardClick}
                  isActionButtonActive={selectedProduct === product._id}
                  key={product._id}
                  labels={[
                    product.broadCategory,
                    product.globalCategory,
                    product.productCategory,
                  ]}
                  product={product}
                  width="267px"
                />
              ))}
          </Row>
        </div>
      )}
    </StyledModal>
  );
};

export default ModalRelatedProducts;
