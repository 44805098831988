import { theme } from "antd";
import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import CardSkeleton from "../../../../../../atom/CardSkeleton";

import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helper/general";
import { isGrowthTypeRiskTolerance } from "../../../../../../utils/helper/specialized";

ChartJS.register(ArcElement, Tooltip);

const START_POINT = 50;
const END_POINT = 100;

const GaugeSection = ({
  investmentObjective,
  productLiveSimulation,
  loading,
}) => {
  const { token } = theme.useToken();

  if (!loading) {
    console.log("productLiveSimulation => ", productLiveSimulation);
  }

  const calculatedPercent =
    productLiveSimulation?.props?.lastQuickSim === 100
      ? 99
      : productLiveSimulation?.props?.lastQuickSim;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const getGaugeLabel = percent => ({
    id: "gaugeChartText",
    afterDatasetDraw(chart, args, options) {
      const {
        ctx,
        data,
        chartArea: { top, bottom, left, right, width, height },
        scales: { r },
      } = chart;

      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;

      const textLabel = ({ text, x, y, fontSize, textBaseLine, textAlign }) => {
        ctx.font = `${fontSize}px sans-serif`;
        ctx.fillStyle = "#666";
        ctx.textBaseline = textBaseLine;
        ctx.textAlign = textAlign;
        ctx.fillText(text, x, y);
      };

      textLabel({
        text: START_POINT,
        x: left,
        y: yCoor,
        fontSize: 16,
        textBaseLine: "top",
        textAlign: "left",
      });

      textLabel({
        text: END_POINT,
        x: right,
        y: yCoor,
        fontSize: 16,
        textBaseLine: "top",
        textAlign: "right",
      });

      textLabel({
        text: `${percent} %`,
        x: xCoor,
        y: yCoor - 20,
        fontSize: 24,
        textBaseLine: "bottom",
        textAlign: "center",
      });

      textLabel({
        text: "chance",
        x: xCoor,
        y: yCoor,
        fontSize: 21,
        textBaseLine: "bottom",
        textAlign: "center",
      });
    },
  });

  if (calculatedPercent === undefined) {
    return <CardSkeleton loading={loading} width={170} height={235} />;
  }

  if (isGrowthTypeRiskTolerance(investmentObjective)) {
    return (
      <CardSkeleton loading={loading} width={170} height={235}>
        <div
          className="chance-of-success-container"
          style={{ textAlign: "center" }}
        >
          <Doughnut
            height="100px"
            data={{
              labels: [],
              datasets: [
                {
                  backgroundColor: [token.colorPrimary, token.color_grey_2],
                  circumference: 180,
                  cutout: "90%",
                  data: [30, 20],
                  rotation: 270,
                },
              ],
            }}
            options={options}
            plugins={[getGaugeLabel(80)]}
          />
          <p style={{ margin: 0 }}>this investment returns between</p>
          {productLiveSimulation?.props && (
            <b style={{ fontSize: 20 }}>
              {getCurrencyValueWithRoundSign(
                productLiveSimulation.props.trajectoryPercentile10thMC[
                  productLiveSimulation.props.trajectoryPercentile10thMC
                    .length - 1
                ][1]
              )}
              {" - "}
              {getCurrencyValueWithRoundSign(
                productLiveSimulation.props.trajectoryPercentile90thMC[
                  productLiveSimulation.props.trajectoryPercentile90thMC
                    .length - 1
                ][1]
              )}
            </b>
          )}
        </div>
      </CardSkeleton>
    );
  }

  // income type risk tolerance selected
  return (
    <CardSkeleton loading={loading} width={170} height={235}>
      <div
        className="chance-of-success-container"
        style={{ textAlign: "center" }}
      >
        <Doughnut
          height="100px"
          data={{
            labels: [],
            datasets: [
              {
                backgroundColor: [token.colorPrimary, token.color_grey_2],
                circumference: 180,
                cutout: "90%",
                data: [
                  calculatedPercent < 50 ? 0 : START_POINT - calculatedPercent,
                  END_POINT - calculatedPercent,
                ],
                rotation: 270,
              },
            ],
          }}
          options={options}
          plugins={[getGaugeLabel(calculatedPercent)]}
        />
        <p style={{ margin: 0 }}>
          this investment will last through retirement
        </p>
      </div>
    </CardSkeleton>
  );
};

export default GaugeSection;
