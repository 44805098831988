import dayjs from "dayjs";
import { Flex, theme } from "antd";

import Folder from "../../../../../../../icon/Folder";

const FolderCard = ({ name, date, onSelect }) => {
  const { token } = theme.useToken();

  return (
    <Flex
      align="center"
      gap={4}
      style={{ width: 127, textAlign: "center", cursor: "pointer" }}
      onClick={onSelect}
      vertical
    >
      <Folder />
      <span style={{ fontWeight: 500, color: token.color_ui_text }}>
        {name}
      </span>
      <span style={{ fontSize: 12, color: token.color_grey_1 }}>
        {dayjs(date).format("MM/DD/YYYY")}
      </span>
    </Flex>
  );
};

export default FolderCard;
