import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import State from "../../../../context";
import { Flex, theme } from "antd";
import LeadsAnalyticsSection from "./components/LeadsAnalyticsSection";
import LevelAnalyticsSection from "./components/LevelAnalyticsSection";
import RecentUsersSection from "./components/RecentUsersSection";

import {
  getLatestSavedProposalProps,
  isManagerAccessNextOnly,
} from "../../../../utils/helper/specialized";

dayjs.extend(quarterOfYear);

const SectionAdvisorDashboardAnalytics = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [quarterAnalytics, setQuarterAnalytics] = useState({});
  const [radialBarSeries, setRadialBarSeries] = useState([]);

  useEffect(() => {
    if (!state.loading && state.managedUsers?.length) {
      const currentYear = +dayjs().format("YYYY");
      const currentQuarter = dayjs().quarter();
      const lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
      const lastQuarterYear =
        currentQuarter === 1 ? currentYear - 1 : currentYear;

      const totalSavedProposals = state.managedUsers?.filter(
        it => it.valueMap?.productMap
      );

      const currentQuarterProposals = totalSavedProposals.filter(
        it =>
          it.valueMap.productMap &&
          +dayjs(
            getLatestSavedProposalProps(it.valueMap.productMap)?.timestamp
          ).format("YYYY") === currentYear &&
          dayjs(
            getLatestSavedProposalProps(it.valueMap.productMap)?.timestamp
          ).quarter() === currentQuarter
      );

      const lastQuarterProposals = totalSavedProposals.filter(
        it =>
          it.valueMap.productMap &&
          +dayjs(
            getLatestSavedProposalProps(it.valueMap.productMap).timestamp
          ).format("YYYY") === lastQuarterYear &&
          dayjs(
            getLatestSavedProposalProps(it.valueMap.productMap).timestamp
          ).quarter() === lastQuarter
      );

      const currentQuarterConversions = currentQuarterProposals.filter(
        it =>
          it.userType === "ConvertedProspect" ||
          it.valueMap?.leadInitial?.isConverted
      );

      setQuarterAnalytics({
        currentQuarterProposals,
        currentQuarterConversions,
        lastQuarterProposals,
      });

      setRadialBarSeries(
        getRadialBarSeries({
          currentQuarterConversions,
          currentQuarterProposals,
        })
      );
    }
  }, [state]);

  const getRadialBarSeries = ({
    currentQuarterConversions,
    currentQuarterProposals,
  }) => {
    const series = [];

    if (state.quarterly?.proposals) {
      const proposalGeneratedPercent =
        (currentQuarterProposals?.length / state.quarterly.proposals) * 100;

      const roundedPercent =
        proposalGeneratedPercent > 100
          ? 100
          : Math.floor(proposalGeneratedPercent);

      series.push({
        backgroundColor: [token.quarterly_goal_proposal_color],
        circumference: (360 * roundedPercent) / 100,
        data: [roundedPercent],
        label: "Proposals Generated",
      });
    } else {
      series.push({
        backgroundColor: [token.quarterly_goal_proposal_color],
        circumference: 360,
        data: [100],
        label: "Proposals Generated",
      });
    }

    if (state.quarterly?.clientConversion) {
      const clientConversionPercent = Math.floor(
        (currentQuarterConversions?.length /
          (state.quarterly.clientConversion ?? 0)) *
          100
      );

      const roundedPercent =
        clientConversionPercent > 100 ? 100 : clientConversionPercent;

      series.push({
        backgroundColor: [token.quarterly_goal_client_color],
        circumference: (360 * roundedPercent) / 100,
        data: [roundedPercent],
        label: "Client Conversions",
      });
    } else {
      series.push({
        backgroundColor: [token.quarterly_goal_client_color],
        circumference: 360,
        data: [100],
        label: "Client Conversions",
      });
    }

    if (state.quarterly?.newAUM) {
      const newAumPercent =
        (currentQuarterProposals?.reduce(
          (acc, elem) =>
            acc +
            (getLatestSavedProposalProps(elem.valueMap?.productMap)
              ?.investmentAmount ?? +elem.proposedAmount),
          0
        ) /
          state.quarterly.newAUM) *
        100;

      const roundedPercent =
        newAumPercent > 100 ? 100 : Math.floor(newAumPercent);

      series.push({
        backgroundColor: [token.quarterly_goal_aum_color],
        circumference: (360 * roundedPercent) / 100,
        data: [roundedPercent],
        label: "New AUM",
      });
    } else {
      series.push({
        backgroundColor: [token.quarterly_goal_aum_color],
        circumference: 360,
        data: [100],
        label: "New AUM",
      });
    }

    return series;
  };

  return (
    <Flex
      className={state.dashboardBlurred && "blurred-content"}
      vertical
      gap={20}
      style={{
        width: token.advisor_page_dashboard_right_side_section_hidden
          ? 822
          : "100%",
      }}
    >
      {!isManagerAccessNextOnly(state.access) && (
        <LevelAnalyticsSection
          radialBarSeries={radialBarSeries}
          quarterAnalytics={quarterAnalytics}
        />
      )}

      {state.access?.next && (
        <LeadsAnalyticsSection managedUsers={state.managedUsers} />
      )}

      <RecentUsersSection />
    </Flex>
  );
};

export default SectionAdvisorDashboardAnalytics;
