import { Button, Flex, Row } from "antd";
import CardProduct from "../../../../molecule/CardProduct";

import { ReactComponent as Gear } from "../../../../icon/gear.svg";

const RiversharesProductsDashboard = ({
  comparedProducts,
  handleCardClick,
  openModal,
  productActions,
  productsList,
  setCompareMode,
}) =>
  productsList?.length && (
    <Flex vertical>
      <Flex
        align="center"
        justify="space-between"
        style={{
          marginBottom: 10,
          paddingBottom: 8,
        }}
      >
        <Flex
          className="pointer"
          gap={8}
          onClick={() => openModal("defaultProductAssumptionsModal")}
        >
          <Gear />
          <span>Default Product Assumptions</span>
        </Flex>
        <Button
          disabled={!comparedProducts?.length}
          size="small"
          shape="round"
          type="primary"
          onClick={() => setCompareMode(true)}
        >
          Compare Mode ({comparedProducts?.length} of 3)
        </Button>
      </Flex>
      <Row style={{ gap: 20 }}>
        {productsList?.map(product => (
          <CardProduct
            key={product._id}
            product={product}
            actions={productActions(product)}
            handleCardClick={handleCardClick}
            labels={[product.riskTolerance, product.allocationType]}
          />
        ))}
      </Row>
    </Flex>
  );

export default RiversharesProductsDashboard;
