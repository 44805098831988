export const LIST_STEP_DATA = [
  {
    description: `<p>Having clear financial goals helps us make smarter decisions today and sets us up for freedom and peace of mind down the road.</p><p>What are your thoughts on where we could take our financial journey?</p>`,
    title: "What’s your aim?",
  },
  {
    description:
      "To effectively achieve your financial goals, we need to choose an investment strategy that not only aligns with your comfort level, but also balances potential risks. It’s important to select an approach that offers the growth needed, while managing the level of risk you're willing to accept.",
    title: "What level of risk are you willing to take?",
  },
  {
    description:
      "Let’s set some basic parameters for this journey. Please select amount you want to invest, and for how long.",
    title: "Amount & Time",
  },
];
