import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    align-items: center;
    border-bottom: 1px solid #c9c8c8;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;

    h3 {
      color: #1e1919;
      font-size: 20px;
      margin: 0;
    }
  }

  main {
    padding: ${({ token }) => token.manager_page_body_padding};
  }

  .ant-table-wrapper .ant-table-thead > tr > td,
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 500;
    background: transparent;
    border-bottom-color: #8093a3;

    &::before {
      display: none;
    }
  }
`;

export default StylesContainer;
