import styled from "styled-components";
import { Flex } from "antd";

import background from "./images/background.svg";

const StylesContainer = styled(Flex)`
  background: url(${background}) no-repeat bottom;
  background-size: contain;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  height: 100vh;

  header {
    width: 100%;

    .copy-container {
      box-sizing: border-box;
      border-bottom: 1px solid #c9c8c8;
      padding: 20px 32px;

      h4 {
        color: #215054;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        margin: 0;
      }

      .ant-btn {
        height: 36px;
      }
    }

    .breadcrumb-container {
      background: #f1f5f6;
      box-sizing: border-box;
      padding: 10px 32px;

      .ant-breadcrumb-link {
        color: #a5a3a3;
        display: block;
        font-weight: 500;

        &.active {
          color: #1e1919;
          font-weight: 700;
        }

        p {
          margin: 8px 0;
        }

        .ant-btn {
          height: 36px;
        }
      }

      .ant-breadcrumb .ant-breadcrumb-separator {
        margin: 8px 24px;
      }
    }

    .ant-progress-line {
      display: block;
      margin-top: -8px;
    }
  }

  main {
    margin: 58px 0 148px;
    position: relative;

    .description-container {
      background: #e6f4ef;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 40px 168px 40px 40px;
      width: 100%;
      max-width: 561px;

      h2 {
        color: #02494e;
        font-size: 28px;
        font-weight: 800;
        letter-spacing: -0.56px;
        line-height: 130%;
        margin: 0 0 24px;
      }

      p {
        color: #02494e;
        font-size: 16px;
        letter-spacing: -0.32px;
        line-height: 150%;
        margin-bottom: 0;
      }
    }

    .form-container {
      position: absolute;
      left: 404px;
      top: 35px;
    }

    .ant-input-number {
      width: 100%;
    }

    .aim-container {
      padding: 10px 0;
      width: 100%;

      .ant-radio-button-wrapper {
        border-radius: 8px;
        border: 1px solid #376265;
        background: #fff;
        height: 63px;
        padding: 14px 6px 14px 24px;
        font-size: 18.8px;
        font-weight: 300;
        color: #1e1919;
        width: 250px;

        &.ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          ) {
          font-weight: 600;
        }

        &::before {
          display: none;
        }
      }
    }

    .risk-container {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      padding: 32px;
      position: relative;
      width: 253px;

      &:before {
        background: linear-gradient(180deg, #a0bfc1 0%, #124f54 100%);
        border-radius: 23px;
        content: "";
        height: 209px;
        position: absolute;
        width: 16px;
      }

      .ant-divider-horizontal {
        margin: 0;
      }

      .ant-radio-wrapper {
        color: #052c2b;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 26.4px;
        padding-left: 50px;
      }
    }

    .investment-values-container {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      padding: 32px 32px 8px;
      width: 318px;
    }
  }

  footer {
    align-items: center;
    background: #fff;
    border-radius: 45px;
    box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    gap: 94px;
    padding: 8px;

    .ant-btn {
      font-size: 16px;
    }
  }

  @media screen and (max-width: ${({ token }) => token.screenMDMax}px) {
    height: 100vh;
    overflow: auto;
    padding: 42px 25px 28px;

    header {
      padding-bottom: 16px;

      .logo {
        height: 38px;
        width: 123px;
      }
    }

    main {
      margin: 40px 0 24px;

      .description-container {
        padding: 12px;
      }

      .form-container {
        position: unset;
        left: unset;
        top: unset;
      }
    }

    footer {
      gap: 24px;
    }
  }
`;

export default StylesContainer;
